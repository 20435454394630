/* ContactForm.module.css */
/* ユーティリティ(bootstrap的なやつ */
/* 各要素の共通オーバーライドセレクタは下の方にある */
button {
  /* ボーダーを削除 */
  border: none;
  /* 背景色を透明にする */
  background-color: transparent;
  /* パディングを削除 */
  padding: 0;
  /* マージンを削除 */
  margin: 0;
}

.inter {
  font-family: 'Inter', sans-serif;
}

.dFlex {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.justifyContentCenter {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

/* 最外コンテナ(全サイズ共通) */
.body {
  display: flex;
  width: 100%;
  justify-content: center;
}

.submitMessage {
  font-family: 'Inter';
}

/* 600px以下の画面のスタイル。スマホ等 */
@media (max-width: 600px) {
  /* Or whatever threshold you choose for smartphones */

  /* 各要素固有スタイル */

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 6.6%;
    width: 100%;
  }


  .titleContainer {
    /* 位置指定の要件が不明 */
    /* formの幅によってこのコンテナの幅は可変にする */
    align-self: center;
    margin-bottom: 82.64px;
    width: 100%;
    /* 文字が削れないように一旦ベタ書き */
  }

  /* .title {
    font-family: 'Inter', sans-serif;
    font-size: 32px !important;
    letter-spacing: -3px ! important;
  } */

  .formContainer {
    width: 100%;
  }

  .formLabel {
    font: normal normal normal 14px/20px Noto Sans CJK JP;
    color: #515D6A;
    margin-bottom: 4px;
    padding-left: 1px;
  }

  .errorMessage {
    margin-top: 3px;
    padding-left: 3px;
    font: normal normal normal 14px/20px Noto Sans CJK JP;
    color: #EA6942;
  }

  .formInputText {
    /* input全ての共通スタイル */
    height: 56px;
    padding-left: 20px !important;
    padding-right: 18px !important;
    font: normal normal normal 16px/25px Noto Sans CJK JP;
    letter-spacing: 0.24px;
    height: 64px;
    border: 1px solid #E0E0E0;
    border-radius: 3px !important;
    box-sizing: content-box;
    color: #515D6A;
  }

  .formInputText:focus {
    /* ボーダーの太さの分だけ外側に余白を取る */
    margin: -2px;
    border: 3px solid #D1D1D1;
    outline: none;
  }

  .formLong {
    /* 1行丸々使うform */
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .formShortContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0px;
  }

  .formShort {
    /* 画面が横に長い時は1行で2つ使うform。このパターンでは1行に一つ */
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    /* 横マージンは設定せず、親コンテナでjustify-content-betweenする */
  }

  .formMessage {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .textArea {
    resize: none;
    padding-top: 10px;
    padding-bottom: 15px;
    height: 238px !important;
  }

  .button {
    display: block;
    position: relative;
    cursor: pointer;
    width: 100%;
    margin-top: 74px;
    margin-bottom: 35px;
  }

  .submitImg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* 要素を中央に移動 */
  }
}




/* 横幅600~1200pxの画面 タブレット等 */
@media (min-width: 600px) and (max-width: 1200px) {

  /* 各要素固有スタイル */

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5.2%;
    width: 100%;
  }


  .titleContainer {
    /* 位置指定の要件が不明 */
    /* formの幅によってこのコンテナの幅は可変にする */
    align-self: center;
    margin-bottom: 145px;
    width: 100%;
    /* 文字が削れないように一旦ベタ書き */
  }

  /* .title {
    font-family: 'Inter', sans-serif;
    font-size: 32px !important;
    letter-spacing: -3px ! important;
  } */

  .formContainer {
    width: 100%;
  }

  .formLabel {
    font: normal normal normal 14px/20px Noto Sans CJK JP;
    color: #515D6A;
    margin-bottom: 3px;
    padding-left: 3px;
  }

  .errorMessage {
    margin-top: 3px;
    padding-left: 3px;
    font: normal normal normal 14px/20px Noto Sans CJK JP;
    color: #EA6942;
  }

  .formInputText {
    /* input全ての共通スタイル */
    height: 64px;
    padding-left: 20px !important;
    padding-right: 18px !important;
    font: normal normal normal 16px/25px Noto Sans CJK JP;
    letter-spacing: 0.24px;
    height: 64px;
    border: 1px solid #E0E0E0;
    border-radius: 3px !important;
    box-sizing: content-box;
    color: #515D6A;
  }

  .formInputText:focus {
    /* ボーダーの太さの分だけ外側に余白を取る */
    margin: -2px;
    border: 3px solid #D1D1D1;
    outline: none;
  }

  .formLong {
    /* 1行丸々使うform */
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
  }

  .formShortContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  .formShort {
    /* 1行で2つ使うform */
    width: 48.3%;
    display: flex;
    flex-direction: column;
    /* 横マージンは設定せず、親コンテナでjustify-content-betweenする */
  }

  .formMessage {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .textArea {
    resize: none;
    padding-top: 10px;
    padding-bottom: 15px;
    height: 275px !important;
  }

  .button {
    display: block;
    position: relative;
    cursor: pointer;
    width: 100%;
    margin-top: 74px;
    margin-bottom: 35px;
  }

  .submitImg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* 要素を中央に移動 */
  }
}

/* 横幅1200~1900pxの画面 miniPC等 */
@media (min-width: 1200px) and (max-width: 1900px) {

  /* 各要素固有スタイル */

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10.35%;
    width: 100%;
  }


  .titleContainer {
    /* 位置指定の要件が不明 */
    /* formの幅によってこのコンテナの幅は可変にする */
    align-self: start;
    width: auto;
    margin-top: -6px;
  }

  /* .title {
    font-family: 'Inter', sans-serif;
    display: inline-block;
    font-size: 32px !important;
    letter-spacing: -3px ! important;
  } */

  .formContainer {
    width: 772px;
    /*どうやら固定値*/
  }

  .formLabel {
    font: normal normal normal 15px/19px Noto Sans CJK JP;
    color: #515D6A;
    margin-bottom: 4px;
    padding-left: 3px;
  }

  .errorMessage {
    margin-top: 3px;
    padding-left: 3px;
    font: normal normal normal 15px/19px Noto Sans CJK JP;
    color: #EA6942;
  }

  .formInputText {
    /* input全ての共通スタイル */
    height: 64px;
    padding-left: 20px !important;
    padding-right: 18px !important;
    font: normal normal normal 16px/27px Inter;
    height: 64px;
    border: 1px solid #E0E0E0;
    border-radius: 3px !important;
    box-sizing: content-box;
    color: #515D6A;
  }

  .formInputText:focus {
    /* ボーダーの太さの分だけ外側に余白を取る */
    margin: -2px;
    border: 3px solid #D1D1D1;
    outline: none;
  }

  .formLong {
    /* 1行丸々使うform */
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
  }

  .formShortContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  .formShort {
    /* 1行で2つ使うform */
    width: 48.3%;
    display: flex;
    flex-direction: column;
    /* 横マージンは設定せず、親コンテナでjustify-content-betweenする */
  }

  .formMessage {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 33px;
  }

  .textArea {
    resize: none;
    padding-top: 10px;
    padding-bottom: 15px;
    height: 275px !important;
  }

  .button {
    display: block;
    cursor: pointer;
    position: relative;
  }

  .submitImg {
    position: absolute;
    left: 0;
  }
}


/* 横幅1900px以上の画面。通常のPC等 */
@media (min-width: 1900px) {

  /* 各要素固有スタイル */

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: min(10.5215%, 201.797px);
    max-width: 1920px;
  }


  .titleContainer {
    /* 位置指定の要件が不明 */
    /* formの幅によってこのコンテナの幅は可変にする */
    align-self: start;
    width: auto;
    margin-top: -6px;
    /* 文字が削れないように一旦ベタ書き */
  }


  /* .title {
    font-family: 'Inter', sans-serif;
    display: inline-block;
    font-size: 40px !important;
    letter-spacing: -3px ! important;
  } */

  .formContainer {
    width: 772px;
    /*どうやら固定値*/
  }

  .formLabel {
    font: normal normal normal 15px/19px Noto Sans CJK JP;
    color: #515D6A;
    margin-bottom: 4px;
    padding-left: 3px;
  }

  .errorMessage {
    margin-top: 3px;
    padding-left: 3px;
    font: normal normal normal 15px/19px Noto Sans CJK JP;
    color: #EA6942;
  }

  .formInputText {
    /* input全ての共通スタイル */
    height: 64px;
    padding-left: 20px !important;
    padding-right: 18px !important;
    font: normal normal normal 16px/27px Inter;
    height: 64px;
    border: 1px solid #E0E0E0;
    border-radius: 3px !important;
    box-sizing: content-box;
    color: #515D6A;
  }

  .formInputText:focus {
    /* ボーダーの太さの分だけ外側に余白を取る */
    margin: -2px;
    border: 3px solid #D1D1D1;
    outline: none;
  }

  .formLong {
    /* 1行丸々使うform */
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
  }

  .formShortContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  .formShort {
    /* 1行で2つ使うform */
    width: 48.3%;
    display: flex;
    flex-direction: column;
    /* 横マージンは設定せず、親コンテナでjustify-content-betweenする */
  }

  .formMessage {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 33px;
  }

  .textArea {
    resize: none;
    padding-top: 10px;
    padding-bottom: 15px;
    height: 275px !important;
  }

  .button {
    display: block;
    cursor: pointer;
    position: relative;
  }

  .submitImg {
    position: absolute;
    left: 0;
  }
}

/* 共通オーバーライドセレクタ */
.formInvalid {
  border: solid 1px #EA6942;
  /* マージンを削除 */
  margin: 0px
}

.formInvalid:focus {
  border: solid 1px #EA6942;
  outline: solid 2px #D1D1D1;
  /* マージンを削除 */
  margin: 0px;
}