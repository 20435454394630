.fade-in-image2 {
    width: 100%;
    height: auto;
    opacity: 1;
    transition: opacity 1s;
}

.fade-in-image2.fade-enter {
    opacity: 0;
}

.fade-in-image2.fade-enter.fade-enter-active {
    opacity: 1;
}

.clip-image {
    clip-path: inset(4% 4% 4% 4%) !important;
    transition: clip-path 0.1s ease-out;
}

.clip-image:hover {
    clip-path: inset(0% 0% 0% 0%) !important;
    transition: clip-path 0.1s ease-in-out;
}