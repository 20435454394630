.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 1s;
}

.fade-image {
    width: 100%;
    height: auto;
}